<template>
	<div class="coupon">
		<div class="tab">
			<div class="tit">
				{{i18n('22')}}
			</div>
			<div class="list">
				<div :class="use_status==0?'item itemAc':'item'" @click="typeChange(0)">
					{{i18n('232')}}({{number}})
				</div>
				<div :class="use_status==1?'item itemAc':'item'" @click="typeChange(1)">
					{{i18n('91')}}
				</div>
				<div :class="use_status==2?'item itemAc':'item'" @click="typeChange(2)">
					{{i18n('92')}}
				</div>
			</div>
		</div>
		<div class="inn">
			<div class="bt">
				<div class="">
					My Earning
				</div>
				<div class="">
					View Benefits
				</div>
			</div>
			<div class="scroll">
				<div class="" style="margin-bottom: 16px;" v-for="(item,index) in listData" :key="index">
					<yhItem :type="use_status" :data='item'></yhItem>
				</div>
				<div class="noData" v-if="noData">
					<div class="">
						<img src="@/assets/noData2.png" alt="">
						<div class="text">
							{{i18n('111')}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {list} from "@/api/coupon.js"
	
	import yhItem from './components/yhItem.vue'
	export default {
		name: "coupon",
		components:{
			yhItem
		},
		data() {
			return {
				number:0,
				noData:false,
				listData:[],
				use_status:0  //0->未使用；1->已使用；2->已过期
			}
		},
		methods: {
			typeChange(type){
				this.use_status=type
				this.list()
			},
			async list(){
				let {data} = await list({use_status:this.use_status})
				this.listData = data
				if(this.use_status==0){
					this.number = data.length
				}
				if(!data){
					this.noData=true
				}else{
					this.noData=false
				}
			},
		},
		created() {
			this.$store.commit('user/setClassifyShow',false)
			this.list()
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.noData{
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	.scroll{
		flex: 1;
		overflow-y: scroll;
		padding: 24px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: baseline;
	}
	.inn{
		flex: 1;
		width: 100%;
		background: #FFFFFF;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
	}
	.inn .bt{
		height: 48px;
		padding: 0 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #56AB7B;
	}
	.inn .bt div:nth-child(1){
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
	}
	.inn .bt div:nth-child(2){
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
	}
	.tab{
		padding-bottom: 16px;
	}
	.tab .tit{
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 49px;
		font-weight: 500;
		padding-top: 10px;
	}
	.tab .list{
		display: flex;
		align-items: center;
	}
	.tab .list .item{
		margin-right: 8px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 500;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 6px;
		height: 28px;
		line-height: 28px;
		padding: 0 10px;
		cursor: pointer;
	}
	.tab .list .itemAc{
		background: #56AB7B;
		color: #fff;
	}
	.coupon{
		padding-bottom: 24px;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}
</style>