import fetch from '@/utils/fetch.js';

//获取用户的优惠券
export function list(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/marketing/coupon/user/list",
		method: 'POST',
		data
	})
}
//获取下单购物车的优惠券
export function couponOrderList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/marketing/coupon/order/list",
		method: 'POST',
		data
	})
}
//使用优惠码
export function useCode(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/marketing/coupon/use/code",
		method: 'POST',
		data
	})
}